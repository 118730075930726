import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';

import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { filter } from 'rxjs/operators';

import * as fromStore from '@app/modules/admission/_store';

import { AuthService } from '@app/core/_guards/auth/auth.service';
import { GoogleAnalyticsEventsService } from '@app/core/_shared/services/google-analytics/google-analytics-events.service';
import * as fromServices from '@app/core/_shared/services/organizations/organizations.service';
import { getCurrentUser, setCurrentAccount } from '@app/core/_shared/services/storage/storage.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('container', { static: true }) container: any;
  @ViewChild('dropdown', { static: true }) dropdown: any;
  @Input()
  set menu(open: boolean) {
    this.isOpen = open;
  }
  @Output()
  notify = new EventEmitter<boolean>();

  isLoggedIn: any;
  isOpen: boolean;
  logoutBox = true;
  currentAccount: any;
  user: any;
  permissions: any;
  baseUrl = '/';
  account: string;

  constructor(
    private router: Router,
    private store: Store<any>,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private eventGa: GoogleAnalyticsEventsService,
    private organizationService: fromServices.OrganizationsService
  ) {
    this.user = getCurrentUser();
  }

  ngOnInit() {
    this.isLoggedIn = this.authService.loggedIn();

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.account = params.a;
    });
    this.router.navigate([], {
      queryParams: {
        'a': null,
      },
      queryParamsHandling: 'merge'
    });

    this.checkUrl();

    if (this.account && this.account !== '') {
      setCurrentAccount(this.account);
      this.currentAccount = this.organizationService.setOrganization(this.account);
    }

    if(!this.currentAccount || this.currentAccount === '') {
      this.store.pipe(select(fromStore.getOrganizationState)).subscribe(res => {
        this.currentAccount = res.currentAccount;
      });
    }
    this.eventGa.customPropreties('dimension1', this.currentAccount.name);
  }

  checkUrl(): void {
    this.changeUrl();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => this.changeUrl());
  }

  changeUrl(): void {
    const currentUrl: string = this.router.url;
    if (currentUrl.includes('/collaborator')) {
      this.baseUrl = '/collaborators';
    } else {
      this.baseUrl = '/';
    }
  }

  openMenu(): void {
    const body = document.getElementsByTagName('body')[0];
    this.isOpen = !this.isOpen;
    this.notify.emit(this.isOpen);

    if (this.isOpen) {
      body.classList.add('sidebar-is-open');
    } else {
      body.classList.remove('sidebar-is-open');
    }
  }

  closeMenu(): void {
    this.logoutBox = false;
  }

  preferences(): void {
    this.router.navigate(['/preferences']);
    this.logoutBox = true;
  }

  offClickHandler(): void {
    this.logoutBox = true;
  }

  logout(): void {
    this.authService.logout();
  }
}
